import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { coldCall, covid19, ogden } from "../utils/generalInformation";

import SimpleParagraph from "../components/simple-paragraph/simple-paragraph";


// markup
const ColdCall = () => {


  return (
    <Layout
      SEOTitle={"The Ogden Rate - United Legal Assistance"}
      SEODescription={"The Ogden rate is an important factor for any insurance broker to understand, as it affects how much claimants can expect to gain from a personal injury claim and how much insurers will be charging for them to pursue it."}
      footerDisclaimer={""}>

      <SimpleParagraph props={ogden} />

    </Layout>
  )

}

export default ColdCall